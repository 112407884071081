import {
  ProbleemHistoriekItemDto,
  ProbleemReden,
  ProbleemType,
  ProfielHistoriekItemDto,
  SysteemConfigHistoriekItemDto,
} from 'parkour-beheer-app-dto';
import { probleemTypes } from './data/probleemTypes.data';
import { probleemReasons } from './data/probleemReasons.data';
import { actionTranslationLabels } from './data/actions.data';

export const getMeldingTypeTranslationKey = (type: ProbleemType) => {
  const findProbleemType = probleemTypes.find((problemType) => problemType.type === type);

  return findProbleemType ? findProbleemType.key : 'melding-types.no-type';
};

export const getMeldingReasonTranslationKey = (type: ProbleemReden) => {
  const findProbleemReason = probleemReasons.find((problemReason) => problemReason.type === type);

  return findProbleemReason ? findProbleemReason.key : 'melding-reasons.no-reason';
};

export const getActionsTranslationKey = (
  type: (
    | ProfielHistoriekItemDto
    | ProbleemHistoriekItemDto
    | SysteemConfigHistoriekItemDto
  )['actie'],
) => {
  return actionTranslationLabels[type];
};
