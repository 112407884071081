<div
  class="rounded bg-[var(--gray-100)] p-4 flex flex-col gap-y-4 border-[1px] border-solid border-[var(--gray-300)]"
>
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-col gap-y-4">
      <div class="flex align-items-center">
        <p-radioButton [value]="true" [formControl]="fornControl" inputId="activate" />

        <label class="ml-2" for="activate">
          {{ enableLabel }}
        </label>
      </div>

      <div class="flex align-items-center">
        <p-radioButton [value]="false" [formControl]="fornControl" inputId="deactivate" />

        <label class="ml-2" for="deactivate">
          {{ disableLabel }}
        </label>
      </div>
    </div>
  </div>
</div>
