import { Pipe, PipeTransform } from '@angular/core';
import {
  AnoniemeRapporteerderDto,
  FullProfielDto,
  IngelogdeRapporteerderDto,
  RapporteerderDto,
} from 'parkour-beheer-app-dto';
import { map, Observable, of } from 'rxjs';
import { ProfielService } from '../../../services/profiel.service';

@Pipe({
  name: 'enrichRapporteerder',
  standalone: true,
})
export class EnrichRapporteerderPipe implements PipeTransform {
  constructor(private readonly profielService: ProfielService) {}

  transform(
    rapporteerder: RapporteerderDto,
  ): Observable<
    AnoniemeRapporteerderDto | (IngelogdeRapporteerderDto & { profiel: FullProfielDto })
  > {
    switch (rapporteerder.type) {
      case 'ANONIEM':
        return of(rapporteerder);
      case 'AANGEMELD':
        return this.profielService
          .getProfielDetails(rapporteerder.profielId)
          .pipe(map((profiel) => ({ ...rapporteerder, profiel })));
    }
  }
}
