import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ChangeHistoryComponent } from '../../../shared/ui/change-history/change-history.component';
import { PageLayoutComponent } from '../../../shared/ui/page-layout/page-layout.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { SysteemConfigService } from '../../../services/systeem-config.service';
import { map } from 'rxjs';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EnableDisableCardComponent } from '../../../shared/components/enable-disable-card/enable-disable-card.component';

@Component({
  selector: 'parkour-systeem-page',
  standalone: true,
  imports: [
    AsyncPipe,
    ChangeHistoryComponent,
    PageLayoutComponent,
    ReactiveFormsModule,
    RouterLink,
    TagModule,
    ToastModule,
    TranslateModule,
    InputTextModule,
    PanelModule,
    InputTextareaModule,
    EnableDisableCardComponent,
  ],
  providers: [MessageService],
  templateUrl: './systeem-page.component.html',
})
export class SysteemPageComponent implements OnInit {
  protected readonly systeemConfig$ = this.systeemService.systeemConfig$;
  protected readonly form$ = this.systeemConfig$.pipe(
    map((config) =>
      this.fb.group({
        titel: this.fb.nonNullable.control(config.titel),
        beschrijving: this.fb.nonNullable.control(config.beschrijving),
        zichtbaar: this.fb.nonNullable.control(config.zichtbaar),
      }),
    ),
  );
  protected readonly historiekItems$ = this.systeemService.systeemConfig$.pipe(
    map((config) => config.historiekItems),
  );

  constructor(
    private readonly systeemService: SysteemConfigService,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.systeemService.retrieveSysteemConfig().subscribe();
  }

  onSubmit(form: { titel: string; beschrijving: string; zichtbaar: boolean }) {
    this.systeemService.updateSysteemConfig(form).subscribe();
  }

  onCancelClick() {
    this.systeemService.retrieveSysteemConfig().subscribe();
  }
}
