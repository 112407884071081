import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../../shared/ui/page-layout/page-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { Button } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { PanelModule } from 'primeng/panel';
import { AsyncPipe, DatePipe } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { ProfielOverviewDto } from 'parkour-beheer-app-dto';
import { ProfessioneelPersoonlijkTranslationKeyPipe } from '../../../professioneel-persoonlijk-translation-key.pipe';
import { ProfielService } from '../../../services/profiel.service';

@Component({
  templateUrl: './overview.page.html',
  standalone: true,
  imports: [
    PageLayoutComponent,
    TranslateModule,
    Button,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    PanelModule,
    DatePipe,
    SkeletonModule,
    TableModule,
    AsyncPipe,
    ProfessioneelPersoonlijkTranslationKeyPipe,
  ],
})
export class OverviewPage implements OnInit, OnDestroy {
  profielen$: Subject<ProfielOverviewDto[]> = new ReplaySubject(1);
  searchForm!: FormGroup<{
    rrn: FormControl<string>;
  }>;
  isSearchLoading = false;
  isGebruikersLoading = false;
  query: string = '';
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly profielService: ProfielService,
  ) {}

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      rrn: this.formBuilder.nonNullable.control<string>('', [
        Validators.required,
        rijksregisterValidator,
      ]),
    });

    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((queryParams: ParamMap) => {
        if (queryParams.keys.length > 0) {
          this.query = stripRrn(queryParams.get('query') ? String(queryParams.get('query')) : '');

          this.searchForm.patchValue({
            rrn: this.query,
          });

          this.fetchGebruikers(this.query);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToDetail(event: TableRowSelectEvent) {
    this.router.navigateByUrl(`/gebruikers/${event.data.id}`);
  }

  clear() {
    const searchRrn = this.searchForm.value.rrn;

    if (searchRrn) {
      this.query = '';

      this.searchForm.reset();
      this.setRouteQueryParams(this.query);
      this.fetchGebruikers(this.query);
    }
  }

  search() {
    const searchRrn = this.searchForm.value.rrn;

    this.searchForm.markAllAsTouched();

    if (searchRrn && this.searchForm.valid) {
      this.isSearchLoading = true;
      this.isGebruikersLoading = true;
      this.query = stripRrn(searchRrn);

      this.setRouteQueryParams(this.query);
      this.fetchGebruikers(this.query);
    } else {
      this.query = '';

      this.setRouteQueryParams(this.query);
      this.fetchGebruikers(this.query);
    }
  }

  setRouteQueryParams(query: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        query,
      },
      queryParamsHandling: 'merge',
    });
  }

  fetchGebruikers(query: string) {
    return this.profielService
      .getProfielen(query)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (gebruikers) => this.profielen$.next(gebruikers),
        complete: () => {
          this.isSearchLoading = false;
          this.isGebruikersLoading = false;

          this.searchForm.markAsPristine();
        },
        error: (e: unknown) => {
          this.isSearchLoading = false;

          throw e;
        },
      });
  }
}

const stripRrn = (rrn: string): string => {
  return rrn.replace(/[.-]/g, '');
};

const rijksregisterValidator = (control: AbstractControl<string>): ValidationErrors | null => {
  const value = stripRrn(control.value);

  if (!value) {
    return null;
  }

  const regex = /^\d{11}$/;
  const isValid = regex.test(value);

  return isValid ? null : { invalidRijksregisternummer: true };
};
