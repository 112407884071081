import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { SysteemConfigDto, UpdateSysteemConfigDto } from 'parkour-beheer-app-dto';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SysteemConfigService {
  get systeemConfig$(): ReplaySubject<SysteemConfigDto> {
    return this._systeemConfig$;
  }

  private _systeemConfig$ = new ReplaySubject<SysteemConfigDto>(1);

  public retrieveSysteemConfig(): Observable<void> {
    return this.http
      .get<SysteemConfigDto>(`${environment.API_BASE_URL}/api/config/systeem-boodschap`)
      .pipe(map((config) => this._systeemConfig$.next(config)));
  }

  constructor(private readonly http: HttpClient) {}

  updateSysteemConfig(config: UpdateSysteemConfigDto) {
    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/config/systeem-boodschap`, config)
      .pipe(switchMap(() => this.retrieveSysteemConfig()));
  }
}
