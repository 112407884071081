import { Pipe, PipeTransform } from '@angular/core';
import { FullProfielDto, ProfielId } from 'parkour-beheer-app-dto';
import { Observable } from 'rxjs';
import { ProfielService } from './services/profiel.service';

@Pipe({
  name: 'profiel',
  standalone: true,
})
export class ProfielPipe implements PipeTransform {
  constructor(private readonly profielService: ProfielService) {}

  transform(profielId: ProfielId): Observable<FullProfielDto> {
    return this.profielService.getProfielDetails(profielId);
  }
}
