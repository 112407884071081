<page-layout class="block max-md:mb-30">
  <div class="flex items-center justify-between gap-x-4" header>
    <div class="space-y-4">
      <h1 class="typo-h1">
        {{ 'system-page.title' | translate }}
      </h1>
    </div>

    <div
      class="max-md:fixed max-md:inset-x-0 max-md:z-10 max-md:bottom-0 max-md:w-full max-md:p-4 max-md:shadow-2 max-md:border-t-[1px] max-md:border-solid max-md:border-[var(--surface-200)] flex items-center gap-x-4 bg-n-1000"
    >
      <button (click)="onCancelClick()" class="max-md:w-full p-button p-button-outlined">
        <span class="p-button-icon pi pi-save"></span>

        <span class="p-button-label ml-2"> {{ 'buttons.cancel' | translate }} </span>
      </button>

      <button class="max-md:w-full p-button" type="submit" form="system-form">
        <span class="p-button-icon pi pi-save"></span>

        <span class="p-button-label ml-2"> {{ 'buttons.save' | translate }} </span>
      </button>
    </div>
  </div>

  <div content>
    <p-toast
      [breakpoints]="{ '768px': { width: 'calc(100% - 2rem)', bottom: '4.5rem', left: '1rem' } }"
      position="bottom-right"
    />

    @if (form$ | async; as form) {
      <form
        (ngSubmit)="onSubmit(form.getRawValue())"
        [formGroup]="form"
        class="grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[4fr_2fr] gap-10"
        id="system-form"
      >
        <div class="min-w-0 flex flex-col gap-y-10">
          <article>
            <p-panel [toggleable]="true">
              <ng-template pTemplate="header">
                <h2 class="typo-h4 text-n-100">Systeemboodschap</h2>
              </ng-template>
              <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-1">
                  <label for="titel">Titel</label>
                  <input pInputText id="titel" [formControl]="form.controls.titel" />
                </div>
                <div class="flex flex-col gap-1">
                  <label for="beschrijving">Beschrijving</label>
                  <textarea
                    class="h-20"
                    pInputText
                    id="beschrijving"
                    [formControl]="form.controls.beschrijving"
                  ></textarea>
                </div>
                <parkour-enable-disable-card
                  enableLabel="Systeemboodschap inschakelen"
                  disableLabel="Systeemboodschap uitschakelen"
                  [fornControl]="form.controls.zichtbaar"
                />
              </div>
            </p-panel>
          </article>
          <div class="min-w-0 flex flex-col gap-y-10">
            @if (historiekItems$ | async; as historiekItems) {
              <change-history [historiekItems]="historiekItems" />
            }
          </div>
        </div>
      </form>
    }
  </div>
</page-layout>
