import { Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TagModule } from 'primeng/tag';
import { FullProfielDto } from 'parkour-beheer-app-dto';
import { ProfessioneelPersoonlijkTranslationKeyPipe } from '../../../../professioneel-persoonlijk-translation-key.pipe';
import { ActivatedFormGroup } from '../detail.page';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  standalone: true,
  imports: [
    PanelModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    TagModule,
    ProfessioneelPersoonlijkTranslationKeyPipe,
  ],
})
export class ProfileCardComponent {
  @Input({ required: true }) form!: ActivatedFormGroup;
  @Input({ required: true }) profiel!: FullProfielDto;
}
