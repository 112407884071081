import { Component, Input } from '@angular/core';
import { ProfessioneelPersoonlijkTranslationKeyPipe } from '../../../professioneel-persoonlijk-translation-key.pipe';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'parkour-enable-disable-card',
  standalone: true,
  imports: [
    ProfessioneelPersoonlijkTranslationKeyPipe,
    RadioButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './enable-disable-card.component.html',
})
export class EnableDisableCardComponent {
  @Input({ required: true }) enableLabel!: string;
  @Input({ required: true }) disableLabel!: string;
  @Input({ required: true }) fornControl!: FormControl<boolean>;
}
