import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ProbleemDto,
  ProbleemId,
  ProbleemSortDirection,
  ProbleemSortProperty,
  ProbleemUpdateDto,
  ProblemenPage,
} from 'parkour-beheer-app-dto';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeldingenService {
  constructor(private httpClient: HttpClient) {}

  getMeldingen(options: {
    pageNumber: number;
    pageSize: number;
    sortProperty: ProbleemSortProperty;
    sortDirection: ProbleemSortDirection;
    searchTerm?: string;
  }): Observable<ProblemenPage> {
    return this.httpClient.get<ProblemenPage>(`${environment.API_BASE_URL}/api/problemen`, {
      params: options,
    });
  }

  getMelding(id: ProbleemId) {
    return this.httpClient.get<ProbleemDto>(`${environment.API_BASE_URL}/api/problemen/${id}`);
  }

  updateMelding(id: ProbleemId, probleemUpdate: ProbleemUpdateDto) {
    return this.httpClient.put<ProbleemUpdateDto>(
      `${environment.API_BASE_URL}/api/problemen/${id}`,
      probleemUpdate,
    );
  }
}
