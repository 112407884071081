import { CanMatchFn, Routes } from '@angular/router';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { LoginPage } from './auth/pages/login-page/login.page';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { OverviewPage as meldingenOverviewPage } from './meldingen/pages/overview/overview.page';
import { DetailPage as meldingenDetailPage } from './meldingen/pages/detail/detail.page';
import { OverviewPage as gebruikersOverviewPage } from './pages/profiel/overview/overview.page';
import { DetailPage as gebruikersDetailPage } from '././pages/profiel/detail/detail.page';
import { inject } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { map, take } from 'rxjs';
import { SysteemPageComponent } from './pages/systeem/systeem-page/systeem-page.component';

const userNotLoggedIn: CanMatchFn = () =>
  inject(AuthService).user$.pipe(
    take(1),
    map((user) => user.rol === 'ANONIEM'),
  );

export const routes: Routes = [
  {
    path: '**',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginPage,
      },
    ],
    canMatch: [userNotLoggedIn],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'meldingen',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'meldingen',
        component: meldingenOverviewPage,
      },
      {
        path: 'meldingen/:id',
        component: meldingenDetailPage,
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'gebruikers',
        component: gebruikersOverviewPage,
      },
      {
        path: 'gebruikers/:id',
        component: gebruikersDetailPage,
      },
    ],
  },
  {
    path: 'systeem',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SysteemPageComponent,
      },
    ],
  },
];
