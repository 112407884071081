import {
  ProbleemHistoriekItemDto,
  ProfielHistoriekItemDto,
  SysteemConfigHistoriekItemDto,
} from 'parkour-beheer-app-dto';

export const actionTranslationLabels: Record<
  (ProfielHistoriekItemDto | ProbleemHistoriekItemDto | SysteemConfigHistoriekItemDto)['actie'],
  string
> = {
  BERICHT_AUTOMATISCH_GEMASKEERD: 'actions.auto-masking',
  BERICHT_MANUEEL_GEMASKEERD: 'actions.manual-masking',
  BERICHT_MANUEEL_ONTHULD: 'actions.manual-reveal',
  GEACTIVEERD: 'actions.activated',
  GEDEACTIVEERD: 'actions.deactivated',
  SYSTEEM_BOODSCHAP_INGESCHAKELD: 'actions.system-message-enabled',
  SYSTEEM_BOODSCHAP_UITGESCHAKELD: 'actions.system-message-disabled',
};
