import { Component, Input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import {
  ProbleemHistoriekItemDto,
  ProfielHistoriekItemDto,
  SysteemConfigHistoriekItemDto,
} from 'parkour-beheer-app-dto';
import { getActionsTranslationKey } from '../../utils';

@Component({
  selector: 'change-history',
  templateUrl: './change-history.component.html',
  standalone: true,
  imports: [PanelModule, TranslateModule, DatePipe, TableModule],
})
export class ChangeHistoryComponent {
  @Input({ required: true }) historiekItems!:
    | ProbleemHistoriekItemDto[]
    | ProfielHistoriekItemDto[]
    | SysteemConfigHistoriekItemDto[];

  @Input() translationParams = {};

  protected readonly getActionsTranslationKey = getActionsTranslationKey;
}
